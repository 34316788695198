import React, { useState } from "react";
import {
  Close,
  Employment,
  Facebook,
  Instagram,
  JobPosting,
  Linkedin,
  MenuIcon,
  OnBoarding,
  Screening,
  Twitter,
  Whatsapp,
  Youtube,
} from "../Utils/base64Icon";
import './SwipeText.scss'

const HomeAndFooter = () => {
  const [mobMenuVisible, setMobMenuVisible] = useState(false);

  const ToggleMenuOpen = () => {
    setMobMenuVisible(true);
  };

  const MenuClose = () => {
    setMobMenuVisible(false);
  };

  return (
    <>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <a href="/">
            <img src={require("../Images/logo.png")} alt="logo" />
          </a>
          {/* <!-- Uncomment below if you prefer to use an image logo --> */}
          {/* <!-- <a href="index.html" className="logo"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>--> */}

          <nav
            id="navbar"
            className={`navbar ${mobMenuVisible ? "navbar-mobile" : ""}`}
          >
            <ul>
              <li>
                <a className="nav-link scrollto active" href="/">
                  Home{" "}
                  {mobMenuVisible ? (
                    <button onClick={MenuClose} className="menu-btn">
                      <img src={Close} alt="close" />
                    </button>
                  ) : (
                    ""
                  )}
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" >
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" >
                  Features
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" >
                  Pricing
                </a>
              </li>
              <li>
                <a className="nav-link scrollto">
                  FAQs
                </a>
              </li>
              <li>
                <a className="nav-link scrollto">
                  Contact
                </a>
              </li>
              <li>
                <a className="getstarted scrollto">Coming Soon</a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          <div className="mobile-nav-toggle">
            <button onClick={ToggleMenuOpen} className="menu-btn">
              <img src={MenuIcon} alt="menuIcon" width={32} />
            </button>
          </div>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero" className="d-flex align-items-center">
        <div
          className="container position-relative"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="row justify-content-center">
            <div className="col-xl-12 text-center">
              <h1 className="wordCarousel font-size-mobile">
                <div className="d-flex justify-content-center">
                  <div>Empower Your</div>
                  <div>
                      <ul className="flip3 text-start">
                          <li>Simplify</li>
                          <li>Strategic</li>
                          <li>Fastest</li>
                      </ul>
                  </div>
                </div>
                <div>Hiring Journey</div>
              </h1>
              <h2>Effortless Solutions for Seamless Management</h2>
            </div>
          </div>
          <div className="text-center">
            <a  className="btn-get-started scrollto">
              Coming Soon
            </a>
          </div>

          <div className="row icon-boxes">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="icon-box">
                <div className="icon">
                  <img src={JobPosting} alt="JobPosting" width={50} />
                </div>
                <h4 className="title">Job posting</h4>
                <p className="description">
                  Effortless hiring with our Job Posting feature. Streamline,
                  reach, and build your team seamlessly.
                </p>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="icon-box">
                <div className="icon">
                  <img src={Screening} alt="Screening" width={50} />
                </div>
                <h4 className="title">Screening</h4>
                <p className="description">
                  Optimize hiring with Vakency's screening for streamlined and
                  efficient recruitment processes.
                </p>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div className="icon-box">
                <div className="icon">
                  <img src={OnBoarding} alt="OnBoarding" width={50} />
                </div>
                <h4 className="title">On Boarding</h4>
                <p className="description">
                  Effortless Onboarding with Vakency for Elevated New Hire
                  Experiences
                </p>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos="zoom-in"
              data-aos-delay="500"
            >
              <div className="icon-box">
                <div className="icon">
                  <img src={Employment} alt="Employment" width={50} />
                </div>
                <h4 className="title">Employment</h4>
                <p className="description">
                  Simplify employment with Vakency's streamlined tools for
                  efficient and rewarding workforce processes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      {/* <!-- ======= Footer ======= --> */}
      <footer id="footer" className="border-top">
        <div className="footer-top ">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 footer-contact">
                <div>
                  <a href="/">
                    <img src={require("../Images/logo-220.png")} alt="logo" />
                  </a>
                </div>
                <p className="pt-3">
                  301, Sai Ram Plaza <br />
                  63, Mangal Nagar, A.B. Road,
                  <br />
                  Indore (M.P.) – 452014
                  <br />
                  <br />
                  <strong>Email:</strong> <a href="mailto:info.vakency@alittech.com">info.vakency@alittech.com</a>
                  <br />
                </p>
              </div>

              <div className="col-lg-3 col-md-6 footer-links py-xxl-5 py-xl-5 py-lg-5 py-0">
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a>Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a>About us</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a>Features</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links py-xxl-5 py-xl-5 py-lg-5 py-0">
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a>FAQs</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a>Contact</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a>Terms & Conditions</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-2 col-md-6 footer-newsletter py-5">
                <a
                  href="https://www.alittech.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="ps-5 ms-3 ">Developed By</div>
                  <img
                    src={require("../Images/alittech.png")}
                    alt="logo"
                    width={200}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container d-md-flex py-4">
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright pt-2">
              &copy; Copyright{" "}
              <strong>
                <span>Vakency</span>
              </strong>
              . All Rights Reserved
            </div>
          </div>
          <div className="social-links text-center text-md-right  pt-md-0">
            <a className="twitter">
              <img src={Twitter} alt="Twitter" width={18} />
            </a>
            <a  className="facebook">
              <img src={Facebook} alt="facebook" width={20} />
            </a>
            <a href="https://www.instagram.com/info.vakency/" className="instagram" target="_blank" rel="noreferrer">
              <img src={Instagram} alt="instagram" width={18} />
            </a>
            <a  className="google-plus">
              <img src={Linkedin} alt="Linkedin" width={18} />
            </a>
            <a className="linkedin">
              <img src={Youtube} alt="Youtube" width={18} />
            </a>
            <a  className="linkedin">
              <img src={Whatsapp} alt="Whatsapp" width={18} />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomeAndFooter;
