import logo from './logo.svg';
import './App.css';

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/js/dist/collapse";
import './Styles.scss';
import HomeAndFooter from './Component/HomeAndFooter';

function App() {
  return (
    <>
      <HomeAndFooter />  
    </>
  );
}

export default App;
